import React from "react"
import { Layout } from "../components"
import { GatsbySeo } from "gatsby-plugin-next-seo"

const Succes = () => {
  return (
    <Layout>
      <GatsbySeo noindex={true} />
      <h1 className="mt-6 font-sans text-2xl font-normal text-skin-general md:mt-12">
        Bedankt voor uw interesse
      </h1>
      <div className="prose prose-zinc">
        <p>
          Wij hebben uw bericht goed ontvangen. U ontvangt binnen enkele
          werkdagen een reactie van ons.
        </p>
      </div>
    </Layout>
  )
}

export default Succes
